import { formatTransactionFee } from '@bondsports/utils';

export const en = {
	loading: 'Loading...',
	somethingWentWrong: 'Something went wrong',
	tryAgain: 'Try Again',
	genericError: 'Oops…  Something went wrong, Please try again',
	googleAddressPlaceholder: 'Enter Address',
	searchModule: {
		tabBasicLabel: 'Search and Book',
		tabAdvancedLabel: 'Advanced Search',
		datePickerBtnLabel: 'change date',
		activityDateLabel: 'Activity date',
		activityLabel: 'Activity',
		activityNone: 'Select activity',
		checkAvailabilityBtn: 'Check Availability',
		spaceTypeLabel: 'Space type',
		spaceTypeNone: 'Select space type',
		durationLabel: 'Duration',
		durationNone: 'Select time duration',
		comingSoon: 'Coming soon',
		advancedSearch: {
			indoor: ' Indoor',
			outdoor: 'Outdoor',
			recurrenceConfigurations: 'Recurrence Configurations',
			date: 'Date',
			recurrence: {
				title: 'Recurrence',
				daily: 'Daily',
				weekly: 'Weekly',
			},
			spaceDetails: 'Space Details',
			startDate: 'Start Time',
			onDays: 'On Days',
			endRecurrence: {
				title: 'End Recurrence',
				after: 'After',
				occurrences: 'Occurrences',
			},
			emptyResults: 'No search results',
		},
	},
	punchCards: {
		cancel: 'Cancel',
		submit: 'Register',
		successModal: {
			title: 'Registration Confirmed!',
			description: 'You are now registered for {program name} on {date}',
			mainButton: 'Thanks',
		},
		newSuccessModal: {
			title: 'Registration Confirmed!',
			registeredFor: 'You have successfully registered for',
			event: 'event',
			plural: 's',
			mainButton: 'Thanks',
			defaultSessionName: 'session',
			of: 'of',
		},
		maxAllowedError: {
			firstPart: 'You have only',
			event: 'event',
			plural: 's',
			endPart: 'left on this punch pass',
		},
	},
	time: {
		hr: 'hr',
		min: 'min',
	},
	forms: {
		gender: {
			FEMALE: 'Female',
			MALE: 'Male',
			OTHER: 'Other',
		},
		invlidMessages: {
			required: 'Required',
			enterValidePhoneNumber: 'Please enter a valide phone number',
		},
	},
	loginDialog: {
		title: 'Welcome back to Bond Sports!',
		email: 'Email Address',
		password: 'Password',
		forgotPassword: 'Forgot password',
		errorMessage: 'Email Address or Password Don’t Match',
		backToLogin: 'Back to Login',
		resetPasswordTitle: 'Reset Password',
		resetPasswordText:
			'Enter the email address you use for BondSports and we’ll send you instructions to reset you password',
		requestPasswordReset: 'Request Password Reset',
		successTitle: 'Check Your Inbox!',
		successTextWithEmail: 'We’ve sent a reset password email to',
		successText: 'Please click the reset password link to set your new password',
		signUpTitle: 'Signup to Bond Sports',
		signupWithInviteTitle: 'You have been invited to join Bond Sports',
		createAccount: 'Create Account',
		confirmPassword: 'Confirm Password',
		passwordRepeat: 'Password Repeat',
		userAlreadyExist: 'There is already an account associated with this email.',
		openA: `I'm opening a`,
		toolTipTextPassword: 'Password must be a minimum of 6 alphanumeric characters',
		// eslint-disable-next-line max-len
		toolTipTextOpenA: `If you are a sports organizer or are booking on behalf of a company, please open an organization account. You will be able to add more users to your organization's account`,
		organization: 'Organization Account',
		personal: 'Personal Account Details',
		changePassword: 'Change Password',
		skip: 'Skip',
		termsLabel: 'I Agree to Bond Sports ',
		termsAndConditions: 'Terms and Conditions',
		organizationSignUpPage: 'Organization SignUp Page',
		familySignupNotes: {
			signup:
				// eslint-disable-next-line max-len
				'WAIT! Do not enter your child’s data here! Please create your account with adult / parent details. You can add family members in the next step.',
			accountDetails:
				// eslint-disable-next-line max-len
				'Note: Please enter YOUR information here. If you want to register a family member, then you will be able to add their details during the registration checkout or under the My Family tab of your account profile.',
		},
	},
	validationErrors: {
		minPassWord: 'Minimum of 6 alphanumeric characters',
		passwordMatch: 'Passwords must match',
		invalidToken: 'Invalid Token',
		loginError: 'Email Address or Password Don’t Match',
		invalidEmail: 'Invalid Email Address',
		invalidTC: 'Please Fill Missing Details and Accept Terms & Conditions',
		missingResource: 'Seems like there is no available resource',
	},
	buttons: {
		thanks: 'Thanks',
		add: 'add',
		save: 'Save',
		edit: 'Edit',
		cancel: 'Cancel',
		continue: 'Continue',
		select: 'select',
		upload: 'Upload',
		browse: 'Browse',
		uploadFile: 'Upload File',
		back: 'Back',
		addAnotherChild: 'Add Another Child',
		pay: 'Pay',
		payFullPrice: 'Pay Full Price',
		requestBooking: 'Request Booking',
		deposit: 'Pay Deposit',
		register: 'Register',
		acceptWaiver: 'Submit',
		acceptTerms: 'I Accept The terms',
		waiverConfirmation: 'Go to profile',
		signAnotherWaiver: 'Sign Another',
		createTeam: 'Create Team',
		createNewTeam: 'Create a New Team',
		ok: 'Ok',
		remove: 'Remove',
		manage: 'Manage',
		replace: 'Replace',
		enterManually: 'Enter manually',
	},
	expandableText: {
		read: 'Read',
		more: 'More',
		less: 'Less',
		viewLess: 'Show less',
		viewMore: 'Show more',
	},
	user: {
		topMenu: {
			myProfile: 'My Profile',
			logout: 'Logout',
			myFamily: 'My Family',
			myTeams: 'My Teams',
			reservations: 'Reservations',
			paymentMethods: 'Payment Methods',
			passes: 'Passes',
			purchaseHistory: 'Purchase History',
			support: 'Support',
		},
		profile: {
			gender: 'Gender',
			birthDate: 'Date of Birth',
			phoneNumber: 'Phone Number',
			emailAddress: 'Adult (Parent) Email Address',
			placeholderEmailAddress: 'Email Address',
			address: 'Address',
			street: 'Street',
			zip: 'Zip Code',
			city: 'City',
			state: 'State',
			mySports: 'My Sports',
			bio: 'Bio',
			name: 'Name',
			firstName: 'Adult (Parent) First Name',
			firstNameFamily: 'First Name',
			placeholderFirstName: 'First Name',
			lastName: 'Adult (Parent) Last Name',
			lastNameFamily: 'Last Name',
			placeholderLastName: 'Last Name',
			selectSportTypes: 'Select Sport Types',
			balances: {
				multiTitle: 'Credit Balance',
				title: (organizationName: string) => `Credit Balance from ${organizationName}`,
				row: (organizationName: string) => `${organizationName}: `,
				empty: `Credit Balance: `,
			},
		},
		family: {
			addAFamilyMember: 'Add a Family Member',
			editFamilyName: 'Edit Family Name',
			addFamilyMember: 'Add Family Member',
			editFamilyMember: 'Edit Family Member',
		},
		reservation: {
			title: 'Reservations',
			table: {
				reservationId: 'Reservation',
				invoiceId: 'Invoice',
				price: 'Price',
				reservationStatus: 'Status',
				name: 'Name',
				reservationDate: 'Date',
				organizationName: 'Organization',
				noData: 'There are no reservation for you yet',
			},
			price: (price: number) => {
				return `$ ${price}`;
			},
			approvalWaitingStatus: `Waiting for approval`,
			status: {
				approved: 'Approved',
				canceled: 'Canceled',
				rejected: 'Rejected',
			},
		},
		teams: {
			title: 'My Teams',
			table: {
				name: 'Name',
				noData: 'There are no teams for you yet',
			},
		},
		registration: {
			title: 'Registration',
			registered: 'registered',
			table: {
				invoiceId: 'Invoice Id',
				price: 'Price',
				registration: 'Registration',
				name: 'Name',
				invoiceDate: 'Invoice Date',
				noData: 'There are no registration for you yet',
			},
		},
		invoices: {
			id: 'ID',
			owner: 'Owner',
			noInvoices: 'There are no purchases to show',
			timePeriods: {
				all: 'All',
				last7Days: 'Last 7 Days',
				last30Days: 'Last 30 Days',
				last90Days: 'Last 90 Days',
				lastYear: 'Last Year',
			},
			status: {
				paid: 'Paid',
				partiallyPaid: 'Partially Paid',
				unpaid: 'Unpaid',
				refunded: 'Refunded',
				voided: 'Voided',
				pending: 'Pending',
				scheduled: 'Scheduled Payments',
			},
			button: {
				clearButtonLabel: 'Clear',
				applyButtonLabel: 'Apply',
				searchButtonLabel: 'Search',
			},
			searchPlaceholder: 'Type Here...',
			invoiceLink: 'View invoice',
			title: 'Purchase History',
			endOfPage: `You've reached the end of the page.`,
		},
		passes: {
			noPasses: 'There are no passes',
			title: 'My Passes',
			participant: 'Participant',
			program: 'Program',
			product: 'Product',
			purchaseDate: 'Purchase Date',
			passesLeft: 'Passes Left',
		},
		punchPassCards: {
			passSechedule: 'Pass Schedule',
			noResourceDefualt: 'TBD',
			passesLeft: 'Passes left',
			expired: 'Expired',
			redeemPass: 'REDEEM PASS',
			showMoreButton: {
				showMore: 'show more',
				showLess: 'show less',
			},
		},
		eventListModal: {
			join: 'JOIN',
			cancel: 'CANCEL',
		},
		paymentMethods: {
			noPaymentSchedules: 'No active payment schedules',
			creditCard: 'Credit card',
			bank: 'Bank',
			invoiceLink: 'View invoice',
			replaceFlow: {
				chooseType: {
					caption: 'Which payment method?',
				},
				pendingFooter:
					'This schedule has a payment pending and cannot be selected. ' +
					'Once the payment clears, the schedule will reflect your updated payment method.',
				chooseMethod: {
					caption: 'Use existing:',
					or: 'Or',
				},
				header: {
					header: 'Change Payment Method',
					body: 'Select new payment method',
					subText: 'Notice! Some payment methods may include a transaction fee.',
				},
				error: { header: 'Error', button: 'OKAY, THANKS' },
				success: {
					header: 'Payment Method Changed',
					body:
						'Payment method has been successfully updated.' +
						'\nFuture scheduled payments for this purchase will now be charged to new payment method:',
					button: 'Done',
				},
			},
		},
	},
	auth: {
		loginBtn: 'Login',
		signupBtn: 'Signup',
	},
	event: {
		eventName: 'Event Name',
		activity: 'Activity',
		eventType: 'Event Type',
		gender: 'Gender',
		participants: 'Participants',
		additionalInformation: 'Additional Information',
		eventLocation: 'Location',
		eventDate: 'Dates',
		eventTime: 'Days & Time',
		typeLabel: 'Event Type',
		eventTypeNone: 'Select event type',
		participantLabel: 'Participants',
		eventGenderLabel: 'Gender',
		eventInfoLabel: 'Additional Info',
		activityLabel: 'Activity',
		genderLabel: 'Gender',
		eventDetails: 'Event Details',
	},
	contact: {
		contactDetailsTitle: 'Contact Details',
		contactUs: 'Contact Us',
	},
	season: {
		seasonSchedule: 'Season Schedule',
		moreProgramSeasons: 'More seasons in this program',
		subSeason: {
			getPastSeasonsButton: 'SHOW PAST EVENTS',
			date: 'Date',
			time: 'Time',
		},
		seasonPicker: {
			selectEmpty: 'Pick an option',
			emptyBody: `This program offers multiple registration options, pick one to start registering,
			 or scroll down to see more details about each option.`,
		},
		time: 'Day & Time',
		minWeek: 'Min / Week',
		earlyBirdRegistration: 'Early Bird Registration',
		regularRegistration: 'Regular Registration',
		lateRegistration: 'Late Registration',
		ends: 'Ends',
	},
	programs: {
		registerNowButtonText: 'Register now',
		comingUpTitle: 'Coming Up',
		activeSeasons: 'Active Seasons',
		pastSeasons: 'Past Seasons',
		picturesTitle: 'Pictures',
		season: {
			seasonRegistrationTitle: 'Registration',
			dates: 'Dates',
			location: 'Location',
			daysTime: 'Days & Time',
		},
		registration: {
			title: {
				earlyBird: 'Early Bird',
				regular: 'Regular',
				late: 'Late',
			},
			throughDate: 'Through',
			starts: 'Registration Starts',
			registrationOver: 'Sorry, registration is over for now',
			registrationYetStarted: 'The registration is not open yet, starts at ',
			windowClosed: 'Sorry, the registration window has closed',
		},
		other: 'other',
		type: {
			camps: 'Camps',
			classes: 'Classes',
			clinics: 'Clinics',
			lessons: 'Lessons',
			tournaments: 'Tournaments',
			clubs: 'Clubs',
		},
	},
	memberShip: {
		registrationTitle: 'Membership Registration',
		registerNow: 'Register Now',
		registrationEnd: 'Registration End',
		registrationPrice: 'Registration Price',
		through: 'Through',
		renewal: 'Renewal Frequency',
		moreMembership: 'More Membership',
		monthly: 'Monthly',
		quarterly: 'Quarterly',
		annually: 'Annually',
		price: 'Price',
		duration: 'Duration',
	},
	checkout: {
		title: 'Checkout',
		rentalTtitle: 'Book Your Experience',
		progress: {
			package: 'Package',
			schedule: 'Schedule',
			addons: 'Add Ons',
			details: 'Details',
			application: 'Application',
			insurance: 'Insurance',
			payment: 'Payment',
			teams: 'Teams',
			familyMembers: 'Family Members',
		},
		teams: {
			title: 'My Teams',
			createYourTeam: 'Create Your Team',
			stepValidationError: 'Please select a team',
			form: {
				dragImageOr: 'Drag the image here or',
				backgroundImg: 'Background Image',
				logoImage: 'Logo image',
				teamName: 'Team Name',
				teamDescription: 'Team Description',
				ageRange: 'Age Range',
				levelOfPlay: 'Level Of Play',
				city: 'City',
				advancedSettings: 'Advanced Settings',
			},
		},
		details: {
			chooseWhoYouReg: "Choose who you're registering",
			multipuleMembersNote: 'You can select multiple participants for the same session and schedule',
			stepValidationError: 'Please select a family member.',
			genericStepValidationError: 'Server error.',
			eventFormTitle: 'Event Details',
			addMembershipTitle: 'Add membership to cart',
			addMembershipSubtitle:
				'Hey, in order to complete registration to our activity, Please purchase one of the memberships',
			membershipMaxparticipantsNotification:
				'You have reached the max number of participants allowed for the selected membership. Please select another membership to continue',
			dates: 'Dates',
			pleaseChooseUpto: 'Please choose up to',
			members: ' members',
			youMustChoose: 'You must choose exactly ',
			youMustChooseAtLease1: 'You must choose at least 1 member',
		},
		addons: {
			addons: 'Add Ons',
			selectAddons: 'Select Add Ons',
			chooseAddons: 'Choose your Add Ons',
			mandatory: 'Mandatory',
			stepValidationError: 'Please choose all the mandatory addons',
			for: 'for',
			hourlyIncrementNote: 'Note: Add ons are only available for rentals set up in hourly increments.\n',
			hourlyIncrementNoteButton: 'Got it',
		},
		application: {
			application: 'Application',
			termsAndConditions: 'Terms & Condition',
			terms: 'Terms',
			waiver: 'Waiver',
			facilitiesQuestionnaire: "Facilities' Questionnaire",
			agreeTo: 'I Agree to',
			selectShirtSize: 'Select Shirt Size',
			select: 'Select',
			yes: 'Yes',
			no: 'No',
			stepValidationError: 'Please fill all required fields.',
			address: { city: 'City', state: 'State', zipCode: 'Zip Code' },
		},
		cart: {
			invoiceSummary: 'Invoice Summary',
			itemsInCart: 'Items in Cart',
			bookingFee: 'Booking Fee',
			total: 'Total',
			add: 'Add To Cart',
			hour: 'hr',
			hours: 'hrs',
			startingFrom: 'Starting From',
			noItems: 'No Items Added To Cart',
			membership: 'Membership',
			addMembership: 'Add Membership To Cart',
			tax: 'Tax',
			transactionFee: 'Transaction fee',
		},
		leagues: {},
		schedule: {
			selectEventsTitle: 'Pick your schedule',
			selectEventsTitlePunchCardAvailable:
				'You may pick the schedule you wish to attend now, or redeem your purchase later. You may also redeem part of your purchase now.',
			stepValidationError: 'Please pick your schedule.',
			pleasSelect: 'Please select exactly',
			eventsOrClasses: 'event',
			manyEventsOrClasses: 'events',
			filter: 'filter',
		},
		package: {
			selectPackageTitle: 'Select Package',
			packagePrice: 'Price',
			stepValidationError: 'Please select a package.',
			na: 'N/A',
			readMore: 'read more',
		},
		space: {
			selectDateTitle: 'Select Date',
			selectSpace: 'Select Space',
			selectTimeSlots: 'Select Time Slots',
			noAvailableSlots: 'No available slots',
			pleaseSelectTimeSlots: 'Please select time slots',
		},
		payment: {
			paymentMethods: 'Payment Methods',
			addCustomerPaymentMethod: 'Add customer payment method',
			unknownStripeError: 'unknown error happened on stripe',
			saveUserPaymentMethodFailed: 'Failed to save user payment method',
			addBankAccount: 'Add Bank Account',
			addBankAccountDetails: 'Please add bank account details',
			selectPaymentMethodTitle: 'Select Your Payment Method',
			addNewCreditCardText: 'Add a new credit card',
			addNewBankAccountText: 'Add a new bank account',
			invoiceDetails: {
				title: 'Invoice Details',
				reservationName: 'Reservation Name',
				startDate: 'Start Date',
				eventDate: 'Event Date',
				registeredMembers: 'Registered Members',
				reservationID: 'Reservation ID',
				sportType: 'Sport Type',
				facility: 'Facility',
				price: 'Price',
			},
			card: {
				defaultFeeNotice: 'Notice! Some payment methods may include a transaction fee.',
				feeNotice: (percentVal?: string, fixedVal?: string, methodType?: string) => {
					const feeText = formatTransactionFee(percentVal, fixedVal);
					const method = methodType || `payment method`;
					return `This ${method} will include a ${feeText} transaction fee.`;
				},
				nameOnCard: 'Name on Card',
				defaultCard: 'Default',
				expirationDate: 'Expiration',
				addCreditCardTitle: 'Credit Card',
				addCreditCardSubtitle: (val: string) => `Charging amount - ${val}`,
				addCreditCardFeeNotice: (percentVal: string, fixedVal: string, methodType: string) => {
					const feeText = formatTransactionFee(percentVal, fixedVal);
					const method = methodType || `payment method`;
					return `Please notice! This ${method} will include a transaction fee of ${feeText}`;
				},
				holderName: 'Card Holder Name',
				number: 'Card Number',
				securityCode: 'Security Code',
				addPaymentMethod: 'Add Payment Method',
				billingAddress: 'Billing Address',
				saveCreditCardToProfile: 'Save credit card to profile',
				savecreditcardAsDefault: 'Save credit card as default',
				remove: {
					modals: {
						error: {
							paymentPlan: 'Cannot remove due to payment plan',
						},
						confirmationModal: {
							title: 'Removing Payment Method',
							description: 'Payment method will be removed permanently. Are you sure you wish to remove it?',
							mainButton: 'Yes, I’m Sure',
							subButton: 'Cancel',
						},
						successModal: {
							title: 'Payment Method Removed',
							description: 'Payment method has been removed successfully.',
							button: 'Done',
						},
						warningModal: {
							title: 'Error',
							description:
								// eslint-disable-next-line max-len
								'This payment method is connected to an active payment schedule.\nTo remove this payment method, you must first change the payment method for all associated scheduled payments.',
							contactInfo: 'To replace payment method please contact support at support@bondsports.co',
							button: 'Change payment method',
							cancel: 'Cancel',
						},
					},
				},
				methodTypes: {
					card: 'credit card',
					us_bank_account: 'ACH bank transfer',
				},
			},
			ach: {
				confirmationDescription: 'Please confirm that you agree to connect your account information to our system.',
				confirmationTitle: 'Confirm',
				confirmButtonText: 'Confirm',
				cancelButtonText: 'Cancel',
				defaultError: "We couldn't process your request. Please contact your bank",
				canceledVerificationError: 'Customer canceled the hosted verification modal. Could not process request',
			},
			done: {
				title: 'You’re All Set! See you out there!',
				message: '',
				myArea: 'Take Me To My Personal Area',
			},
			deposit: {
				title: 'Pay Deposit',
				subTitle: 'You may choose to pay any sum with a minimum of $',
				inputLabel: 'The amount you choose to pay',
				errorLabel: 'You must pay a minimum of $',
			},
			free: { title: 'Select Your Payment Method' },
			stepValidationError: 'Please select a payment method.',
			charge: {
				missingDataError:
					'Missing required data for the purchase, please start your checkout from the beginning and try again.',
			},
			promoCodes: {
				addPromoCode: 'Add promo code',
				inputInfo:
					'Only one promo-code may be used per purchase. To select a new promo-code, the existing code must be removed.',
				inputLabel: 'Enter promo code (One promo code per purchase)',
				applyButton: 'apply',
				invalidCode: 'The code entered is invalid.',
			},
		},
	},
	organization: {
		title: 'Organization',
	},
	feedRow: {
		buttons: {
			shareText: 'Share',
			favoriteText: 'Favorite',
		},
	},
	spaces: {
		spaces: 'Spaces',
		moreSpaces: 'More spaces at this facility',
		pricing: {
			title: 'Pricing',
			addonTitle: 'Addons',
			startFrom: 'starting from',
			shortHour: 'HR',
			fullDay: 'Full',
		},
		location: {
			title: 'Location',
		},
	},
	facilities: {
		openingHours: {
			title: 'Opening Hours',
		},
		amenities: {
			title: 'Amenities',
		},
		location: {
			title: 'Location',
		},
	},
	gallery: {
		pictures: 'Pictures',
	},
	leagues: {
		pastSeasons: 'Past seasons',
		upcomingSeasons: 'Coming up',
		activeSeasons: 'Active seasons',
		comingUp: 'Coming Up',
		status: 'Status',
		registraionOpen: 'Registration open',
		registraionOpens: 'Registration opens',
		emptySeason: 'Select Season',
		startingAt: 'Starting at',
		otherLeagues: 'Other leagues',
		registrationOpen: 'Registration open',
		registrationOpens: 'Registration opens',
		leagueOrganizer: 'League Organizer',
		aboutLeague: 'About League',
		individual: 'Individual',
		team: 'Team',
	},
	waiver: {
		title: 'Waiver',
		family: {
			title: 'Who are you signing a waiver for?',
			subTitle: 'Select a family member and complete the form',
			noSelectedMemberError: 'Please select a family member in order to sign the waiver',
		},
		Questionnaire: { title: (title?: string) => `Please complete ${title || 'questionnaire'}` },
		formValidationError: 'Please fill all required fields.',
		confirmation: {
			title: 'Success!',
			completed: (title: string) => `${title || 'Waiver'} completed`,
			fallbackFormTitle: 'Waiver',
			formValidation: 'Your waiver form is valid for 1 year',
			memberName: 'Name',
			WaiverDate: 'Signed date',
		},
		cancel: 'cancel',
	},
	leagueSeasons: {
		registraion: 'Registration',
		division: 'Division',
		standings: 'Standings',
		gamesPlayed: 'GP',
		wins: 'W',
		loses: 'L',
		draws: 'D',
		winPercentage: 'PCT',
		points: 'PTS',
		overTime: 'OT',
		pontsDifference: 'PD',
		showAllStandings: 'Show all standings',
		theTeams: 'The teams',
		viewMore: 'View more',
		viewLess: 'View less',
		aboutSeason: 'About season',
		activeSeasons: 'Active seasons games',
		teams: 'Teams',
		date: 'Date',
		time: 'Time',
		vs: 'vs.',
		location: 'Location',
		showMore: 'Show More',
		showLess: 'Show Less',
		otherSeasons: 'Other seasons in',
	},
	NumberRange: {
		title: 'Between (You may search a time frame that is larger than your event duration)',
		from: 'from',
		to: 'to',
		am: 'AM',
		pm: 'PM',
	},
	levelsOfPlay: {
		semiPro: 'SEMI_PRO',
		advanced: 'ADVANCED',
		intermediate: 'INTERMEDIATE',
		beginner: 'BEGINNER',
	},
	longDescriptionTitle: 'About',
	team: {
		inviteMembers: 'invite members',
		members: 'Members',
		pendingInvites: 'Pending invites',
		people: 'People',
		firstName: 'First Name',
		lastName: 'Last Name',
		email: 'Email',
		phoneNumber: 'Phone Number',
		add: 'Add',
		sendInvite: 'Send invite',
		teamMembers: 'Team Members',
		goBack: 'go back to team page',
		events: 'Events',
		pastEvents: 'Past Events',
		upcomingEvents: 'Upcoming Events',
		noPastEvents: 'No Past Events',
		noUpcomingEvents: 'No Upcoming Events',
		eventName: 'Event Name',
		location: 'Location',
		date: 'Date',
		time: 'Time',
		noTeamMembers: 'There are currently no members on this team',
		inviteError: 'Oops… looks like we have a problem sending the team invites',
		inviteSuccess: {
			title: 'Awesome, we’ve sent an email to your team members',
			details: 'and you will be notified when they join the team',
		},
		iviteFailure: 'Oops… looks like we have a problem sending the team invites',
		familyMember: 'Please choose a family member',
		thankForJoining: 'Thanks for joining',
		completeRegistration: 'Complete your registration.',
		join: 'Join',
		application: {
			teamRegistered: 'Your team has registered to',
			completeapplicationForm: 'Complete their application form',
			acceptApplication: 'I accept the appication',
			warning:
				'If you are a youth team coach, and want to invite your own family member, please send the invite to a different email address',
		},
		notPermittedUser: "Sorry, Invite can't be used",
		joinTeamError: "You're not allowed to join the team",
		usedTokenError:
			'Sorry, this is invite is no longer valid, please ask your captain or coach to send a new invite to the team',
	},
	error: 'Error',
	errorDetails: 'Please try again, or contact support@bondsports.co',
	detailsChangedAlert: {
		title: 'Your details changed',
		details: 'We notice you changed your details.',
		saveProfile: 'Do you want to save the changes to your profile?',
		yesUpdate: 'Yes, update',
		noLeaveIt: 'No, leave it',
	},
	currency: {
		USD: {
			symbol: '$',
		},
	},
	freeRegistrationBox: {
		freeOfCharge: 'Free of Charge',
		thisOneIsOnUs: 'This one is on us, free of charge',
		enjoy: 'ENJOY the activity and come back soon',
	},
	paymentTypeLabel: {
		addNewPaymentMethod: 'Add New Payment Method',
		addCreditCard: 'Add Credit Card',
		addACH: 'Add ACH',
		creditCard: 'Credit card',
		bankAccount: 'Bank account',
		enterManual: 'Enter manual',
		enterInstant: 'Enter instant',
		acountBalance: 'Account balance',
		cash: 'Cash',
	},
	address: { city: 'City', state: 'State', zipCode: 'Zip Code' },

	generalError: {
		title: 'Something went wrong',
		info: 'We take care of it',
	},
	tvMonitor: {
		today: 'TODAY',
		time: 'TIME',
		configurationError: 'Sorry not valid configuration, please contact support',
	},
	websiteSchedule: {
		yetPublished: 'has not yet published the schedule for ',
		emptyState: 'Seems like there are no events on this day',
		daily: 'Daily',
		weekly: 'Weekly',
		today: 'Today',
		pickYourProgram: 'Pick Your Program',
		yetPublishedShort: 'Yet Published',
		event: { join: 'Join', register: 'Register' },
	},
	paymentPlanModal: {
		title: 'Pay Deposit',
		description: 'Choose to pay any sum with a minimum of ',
		inputLabel: 'The amount you choose to pay',
		PaymentPlan: 'Payment Plan',
		cancel: 'Cancel',
		apply: 'Apply',
		due: 'Due Later',
		selectLabel: 'Number of payments',
		every: 'Every',
		ofTheMonth: 'of the month',
		st: 'st',
		th: 'th',
		depositIsLessThanMinimumError: 'Then deposit must be more than minimum',
		depositIsMoreThanPriceError: "The deposit can not be more than the product's price",
		step2: {
			title: 'Payment Plan',
			chargingDeposit: 'Charging deposit',
			payments: 'payments',
			each: 'each',
			date: 'Date',
			amount: 'Amount',
			pay: 'pay',
		},
	},
	paymentLandingPage: {
		header: {
			title: 'Receipt no.',
		},
		billingInfo: {
			title: 'Billing Info',
			purchaseDate: 'Purchase date',
			customer: 'Customer',
		},
		details: {
			title: 'Details',
			summary: {
				subtotal: 'Subtotal',
				taxRate: 'Tax Rate',
				membershipDiscount: 'Membership Discount',
				total: 'Total',
				totalDue: 'Amount Due',
				tax: 'Tax',
				taxInc: 'Tax Inc.',
				fees: 'Fees',
			},
			table: {
				columns: {
					item: 'Purchased Items',
					quantity: 'Qty',
					price: 'Price',
					tax: 'Tax',
					total: 'Total',
				},
			},
		},
		landingPage: {
			title: 'receipt Page',
		},
		checkout: {},
		checkoutCard: {
			title: 'receipt from',
			invoiceNum: 'receipt no.',
			memo: 'Memo',
			download: 'Download',
			pay: 'Pay receipt',
			paidOn: 'Paid on',
		},
		paymentForm: {
			title: 'Complete receipt payment',
		},
		paymentMethods: {
			title: 'Payment Method',
			description: 'Select your payment method. You can split the payment into several different payment methods.',
			newMethod: '+ Credit/Debit card',
			saved: 'Saved',
			pay: 'Pay',
		},
		paymentsSection: {
			title: 'Payment Method',
			table: {
				columns: {
					id: 'Receipt id',
					amount: 'Amount',
					fees: 'Fees',
					method: 'Method',
					date: 'Date',
				},
			},
		},
		notes: {
			title: 'Notes',
		},
		payments: {
			paid: 'Paid',
			partial: 'Partially paid',
			not_paid: 'Unpaid',
		},
		appendix: {
			title: 'Schedule',
			scheduleTab: {
				columns: {
					description: 'Description',
					addons: 'Add Ons',
					dayTime: 'Day & Time',
					resource: 'Resource',
					event: 'Event Name',
				},
				info: {
					title: 'Schedule #',
					schedule: 'Schedule',
					participant: 'Participant',
					program: 'Program',
					products: 'Products',
					sessions: 'Sessions',
					more: 'More...',
				},
			},
		},
	},
	invoiceLandingPage: {
		pageHeader: {
			title: 'Invoice Payment',
		},
		header: {
			title: 'Invoice no.',
		},
		billingInfo: {
			title: 'Billing Info',
			purchaseDate: 'Purchase date',
			customer: 'Customer',
		},
		details: {
			title: 'Details',
			summary: {
				subtotal: 'Subtotal',
				taxRate: 'Tax Rate',
				membershipDiscount: 'Membership Discount',
				total: 'Total',
				totalDue: 'Amount Due',
				tax: 'Tax',
				taxInc: 'Tax Inc.',
			},
			table: {
				columns: {
					item: 'Purchased Items',
					quantity: 'Qty',
					price: 'Price',
					tax: 'Tax',
					total: 'Total',
				},
			},
		},
		paymentsSection: {
			paymentAmount: {
				validationErrors: {
					emptyValue: 'Please enter an amount.',
					paymentAmountMoreThenTotal: "You can't pay more than the cost of the invoice.",
					lessThenDownPayment: 'Please enter an amount. The minimum deposit is',
				},
			},
			title: 'Payments',
			table: {
				columns: {
					customerName: 'Customer Name',
					id: 'Receipt ID',
					amount: 'Amount',
					method: 'Method',
					date: 'Date',
				},
			},
			summary: {
				fees: 'Fees',
				amountPaid: 'Amount Paid',
				amountDue: 'Amount Due',
			},
		},
		landingPage: {
			title: 'Invoice Page',
		},
		checkout: {},
		checkoutCard: {
			title: 'Invoice from',
			invoiceNum: 'Invoice no.',
			memo: 'Memo',
			download: 'Download',
			pay: 'go to payment',
			paidOn: 'Paid on',
		},
		paymentForm: {
			title: 'Complete invoice payment',
		},
		paymentMethods: {
			title: 'Payment Method',
			description: 'Select your payment method. You can split the payment into several different payment methods.',
			newCreditCard: '+ Credit/Debit card',
			newACH: '+ ACH',
			saved: 'Saved',
			pay: 'Pay',
			cancel: 'Cancel',
			leftToPay: 'left to pay:',
			'gift-card': 'Gift Card',
			check: 'Check',
			balance: 'Balance',
			us_bank_account: 'ACH',
		},
		notes: {
			title: 'Notes',
		},
		payments: {
			paid: 'Paid',
			partial: 'Partially paid',
			not_paid: 'Unpaid',
		},
		appendix: {
			title: 'Schedule',
			scheduleTab: {
				columns: {
					description: 'Description',
					addons: 'Add Ons',
					dayTime: 'Day & Time',
					resource: 'Resource',
					event: 'Event Name',
				},
				info: {
					title: 'Schedule #',
					schedule: 'Schedule',
					participant: 'Participant',
					program: 'Program',
					products: 'Products',
					sessions: 'Sessions',
					more: 'More...',
				},
			},
		},
		errors: {
			genericError: {
				title: 'Something went wrong',
				description: 'We have some issues we are fixing now',
				mainButton: 'Try later',
				secondButton: 'Cancel',
			},
			ccError: {
				title: 'Credit card error',
				description: 'We have some issues with the credit card payment',
				mainButton: 'OKAY',
			},
		},
	},
	reservationFailed: `We're sorry, but due to an error in the process, your reservation
	 wasn't created. We're working on fixing it, but for now - please contact support`,
	stripePaymentDeclineErrorCodes: {
		unknown: 'unknown error happened on stripe',
		generic_decline: 'Payment declined.',
		insufficient_funds: 'Insufficient funds.',
		lost_card: 'Card reported lost.',
		stolen_card: 'Card reported stolen.',
		expired_card: 'Card expired.',
		incorrect_cvc: 'Incorrect CVC.',
		processing_error: 'Processing error.',
		incorrect_number: 'Incorrect card number.',
		card_velocity_exceeded: 'Too many failed attempts. Please try again tomorrow.',
		restricted_card: 'Card is blocked. Contact support@bondsports.co for assistance.',
	},
};
